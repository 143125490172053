/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root {
  --ion-color-primary: #2a435f;
  --ion-color-primary-rgb: 42, 67, 95;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #253b54;
  --ion-color-primary-tint: #3f566f;

  --ion-color-secondary: #df2826;
  --ion-color-secondary-rgb: 223, 40, 38;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #c42321;
  --ion-color-secondary-tint: #e23e3c;

  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 223, 40, 38;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #c42321;
  --ion-color-tertiary-tint: #e23e3c;

  --ion-color-success: #2a435f;
  --ion-color-success-rgb: 42, 67, 95;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #253b54;
  --ion-color-success-tint: #3f566f;

  --ion-color-warning: #df2826;
  --ion-color-warning-rgb: 223, 40, 38;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #c42321;
  --ion-color-warning-tint: #e23e3c;

  --ion-color-danger: #df2826;
  --ion-color-danger-rgb: 223, 40, 38;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c42321;
  --ion-color-danger-tint: #e23e3c;

  --ion-color-dark: #2a435f;
  --ion-color-dark-rgb: 42, 67, 95;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #253b54;
  --ion-color-dark-tint: #3f566f;

  --ion-color-medium: #2a435f;
  --ion-color-medium-rgb: 42, 67, 95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #253b54;
  --ion-color-medium-tint: #3f566f;

  --ion-color-light: #ececec;
  --ion-color-light-rgb: 236, 236, 236;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d0d0d0;
  --ion-color-light-tint: #eeeeee;
}

h2 {
  font-size: 28px !important;
  color: #2a435f;
}

ion-content {
  --background: #white;
}

@media screen and (max-width: 992px) {
  .beeex-profile-content {
    font-size: 20px;
  }

  .beeeex-profile-label {
    font-size: 20px;
  }
}

@media screen and (min-width: 992px) {
  .beeex-button-profile {
    padding-top: 9%;
  }

  .beeex-profile-info {
    margin-top: -20px;
  }

  .beeex-comp-infos {
    padding-right: 30px;
  }
}

.beeex-comp-infos {
  text-align: left;
}

.avatar-top {
  margin-left: 3%;
  margin-bottom: 3%;
  border: 5.5px solid black;
  border-radius: 324px 1px 328px 1px/1px 268px 1px 277px;
  max-width: 100%;

  @media (min-width: 992px) {
    margin-top: 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1480px) {
  .avatar-container {
    max-width: 309px;
  }
}

@media only screen and (min-width: 1480px) {
  .avatar-container {
    max-width: 389px;
  }
}

.beeex-inputform {
  border-radius: 20px;
}

.avatar-container {
  margin-left: auto;
  background: linear-gradient(to bottom, #df2826 0, #df2826 100%) no-repeat;
  background-size: calc(100% - 12%) calc(100% - 13%);
  background-position: bottom left;
}

ion-thumbnail {
  height: 100%;
  width: 333px;
  max-width: 99%;
}

.popover-content.sc-ion-popover-md {
  background: transparent;
}

.ion-color-tertiary {
  --ion-color-base: #2a435f !important;
}

.searchbar-input.sc-ion-searchbar-md {
  border: 2px solid #2a435f;
  border-radius: 360px;
  border-style: solid;
  padding-top: 5px;
  padding-bottom: 6px;
  color: #2a435f;
}

ion-alert {
  .alert-head.sc-ion-alert-md + .alert-message.sc-ion-alert-md {
    font-size: 18px;
    font-weight: 500;
    color: #2a435f !important;
  }

  a {
    text-decoration: underline;
  }

  h2 {
    color: #2a435f !important;
  }

  .alert-button.sc-ion-alert-md {
    background-color: white !important;
    color: #10324e !important;
    text-transform: inherit !important;
    font-size: 20px;
  }
}

.width-half {
  width: 50%;
  display: inline-flex;
  padding-right: 10px;
}

.width-2 {
  width: 16.6%;
  display: inline-flex;
  padding-right: 10px;
}

.width-3 {
  width: 24.9%;
  display: inline-flex;
  padding-right: 10px;
}

.width-4 {
  width: 33.3%;
  display: inline-flex;
  padding-right: 10px;
}

.width-5 {
  width: 41.6%;
  display: inline-flex;
  padding-right: 10px;
}

.width-6 {
  width: 50%;
  display: inline-flex;
  padding-right: 10px;
}

.width-7 {
  width: 58.3%;
  display: inline-flex;
  padding-right: 10px;
}

.width-8 {
  width: 66.6%;
  display: inline-flex;
  padding-right: 10px;
}

.width-9 {
  width: 75%;
  display: inline-flex;
  padding-right: 10px;
}

.width-10 {
  width: 83.3%;
  display: inline-flex;
  padding-right: 10px;
}

.width-11 {
  width: 91.6%;
  display: inline-flex;
  padding-right: 10px;
}

.width-1 {
  width: 8.3%;
  display: inline-flex;
  padding-right: 10px;
}

.width-12 {
  width: 100%;
  display: inline-flex;
  padding-right: 10px;
}

ion-select-popover {
  --min-width: 320px !important;
  --width: 320px !important;
}

.beeex-inputform {
  border: 3px solid #313e63;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 32px;
  background-color: #313e6305;

  ion-input:not(.ion-color-danger),
  ion-label:not(.ion-color-danger),
  ion-textarea:not(.ion-color-danger),
  ion-select:not(.ion-color-danger),
  ion-text:not(.ion-color-danger),
  ion-spinner:not(.ion-color-danger) {
    color: #2a435f;
  }

  ion-item {
    color: white;
    --padding-start: 9px;
    --border-width: 0.55px;
    --border-radius: 10px;
    --border-style: solid;
    margin-top: 6px;
    padding-left: 10px;
    --inner-border-width: 0px !important;
  }

  ion-label {
    --inner-border-width: 0px !important;
  }

  ion-input {
    caret-color: #2a435f;
    --inner-border-width: 0px !important;
  }

  ion-textarea {
    caret-color: #2a435f;
  }

  ion-item.ion-invalid.ion-touched {
    --border-color: #2a435f;
  }

  .item-interactive.ion-invalid {
    --highlight-background: #313e63 !important
  ;
  }
  .item-interactive.ion-valid {
    --highlight-background: #313e63 !important
  ;
  }

  .item-background-color {
    --ion-item-background: #ffffff00;
  }

  ion-item {
    --background: transparent;
  }

  ion-accordion {
    background-color: transparent;
  }
}

.beeex-info-box-content {
  .move-img {
    -webkit-animation: mover 3s infinite alternate;
    animation: mover 3s infinite alternate;
  }
}

@media (max-width: 770px) {
  .beeex4-content-container h1 {
    text-align: center !important;
  }
}

.beeex-form-container {
  .move-img {
    -webkit-animation: mover 3s infinite alternate;
    animation: mover 3s infinite alternate;
  }

  ion-grid {
    height: 100%;
  }
  ion-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  p {
    font-size: 17px !important;
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

h1 {
  color: var(--ion-color-primary);
  line-height: 1em;
  font-weight: 600;
}

.beeex-form-container:after {
  content: "";
  display: table;
  clear: both;
}

.beeex-form-validator {
  font-size: 14px;
  color: #df2826 !important;
}

.beeex-form-message {
  font-size: 16px;
  color: #df2826 !important;
  display: block;
}

.beeex-form-link {
  color: #2a435f;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}

.toolbar-title {
  font-weight: bold;
}

#beeex-main-container {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.beeex-submitbtn-white {
  border: 2.8px solid #2a435f;
  font-weight: 600;
  border-radius: 100px;
  color: #2a435f;
}

.beeex-submitbtn-blue {
  border: 2px solid #acacac;
  border-radius: 100px;
  color: black;
  font-size: 24px;
  letter-spacing: inherit;
  height: 58px;
}

#beeex-registerbtn {
  --box-shadow: none;
  --border-radius: 300px;
}

.registerbtn-big {
  border: 3px solid var(--ion-color-secondary);
  border-radius: 300px;
  color: var(--ion-color-secondary);
}

.beeex-info-box-content:hover {
  background-color: #ececec;
}

.beeex-info-box-content {
  text-align: center;
  background-color: #f7f8f9;
  padding: 30px 20px 30px 20px;
  border-radius: 12px;
  height: 100%;

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #2a435f;
  }

  p {
    font-size: 14px;
  }

  fa-icon {
    font-size: 50px;
  }
}

ion-router-outlet {
  z-index: -1;
}

ion-header {
  z-index: 0;
}

.beeex-readmore-btn {
  border-radius: 10px;
  margin-top: 0px;
  color: white;
  letter-spacing: inherit;
  height: 38px;
}

.beeex-compinfo {
  line-height: 14px;
}

.beeex-invalid-red {
  .beeex-inputform ion-item.ion-invalid.ion-touched {
    border-bottom: 1px solid #df2826;
  }
}

.item-native {
  margin-bottom: 4px !important;
  background-color: rgb(247, 247, 247) !important;
  border: 0px !important;
}

.beeex-compname {
  font-size: 23px;
  color: var(--ion-color-primary);
}

.beeex-compname-sub {
}

.beeex-supportedfields {
  color: var(--ion-color-primary);
  font-size: 18px;
}

.beeex-supportedfields-container {
  border-radius: 12px;
  border: 1px solid lightgray;
  padding: 14px;
}

.beeex-hide {
  display: none;
}



/**desktop**/
@media (min-width: 992px) {
  .action-sheet-container.sc-ion-action-sheet-md {
    height: 67%;
  }


  .beeex-sub-main-container {
    width: 77%;
    margin-left: auto;
    margin-right: auto;
    min-width: 992px;
  }

  .beeex-header-ausgleich {
    margin-bottom: -113px;
  }

  .action-sheet-title.sc-ion-action-sheet-md {
    color: #2a435f !important;
  }

  .beeex-readmore-btn {
    float: right;
  }

  h1 {
    font-size: 44px;
    margin-top: 0px;
  }

  .beeex-form-container {
    background-size: contain;
    background-position: 38%center;

    .beeex-inputform {
      .swiper-pagination {
        top: 0;
      }

      .swiper-pagination-bullet {
        width: 30px;
      }
    }
  }

  .beeex-reminder-banner ion-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .beeex-hideondesktop {
    display: none !important;
  }

  #beeex-main-container {
    margin-top: 150px;
    width: 77%;
    min-width: 992px;
  }

  .beeex-info-box {
    height: 100%;

    .right {
      margin-left: 12px;
      height: 100%;
    }
    .left {
      margin-right: 12px;
      height: 100%;
    }
    .middle {
      margin-left: 6px;
      margin-right: 6px;
      height: 100%;
    }
  }

  .beeex-content-container {
    margin-bottom: 70px;
  }
}

.beeex-inputform {
  .swiper-pagination {
    left: 0;
    text-align: left;
  }

  .swiper-pagination-bullet {
    border-radius: 12px;
    height: 6px;
  }
}

.beeex-reminder-banner {
  background-color: var(--ion-color-primary);
  border-radius: 12px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-image: url("/assets/questionmark-icon.png");
  background-repeat: no-repeat;

  h2 {
    color: white;
    font-weight: bold;
  }

  p {
    color: white;
    font-size: 14px;
    font-weight: 500;
  }
}

.beeex-container-public {
  background-color: white;
  color: #2a435f;
  border-color: #2a435f;
  border: 2px solid;
  p {
    font-weight: 400;
  }
  h2 {
    color: #2a435f;
  }
  ion-button {
    color: #2a435f;
    border-color: #2a435f;
  }
}

#beeex-profile-edit-stepper .mat-horizontal-stepper-header-container{
  display: none !important;
}

.item-label-stacked.sc-ion-input-ios-h[_ngcontent-serverApp-c223], .item-label-stacked[_ngcontent-serverApp-c223] .sc-ion-input-ios-h[_ngcontent-serverApp-c223], .item-label-floating.sc-ion-input-ios-h[_ngcontent-serverApp-c223], .item-label-floating[_ngcontent-serverApp-c223] .sc-ion-input-ios-h[_ngcontent-serverApp-c223] {
  border-bottom: transparent !important;
}

.beeex-companyprofileedit-container{
  ion-modal{
    --height: 674px !important;
  }
}


.call-contact-alert{
  h3{
    font-size: 26px !important;
  }

  .alert-sub-title {
    text-align: center !important;
  }
}

#tab-menu {
  ion-button {
    //background-color: white;
    //color: #2a435f;
    --box-shadow: none;
  }


}

.beeex-container-hidden {
  background-color: white;
  color: #df2826;
  border-color: #df2826;
  border: 2px solid;
  p {
    font-weight: 400;
  }
  h2 {
    color: #df2826 !important;
  }
  ion-button {
    color: white;
    border-color: #df2826;
    background-color: #df2826;
  }
}

.beeex-profile-publish-container {
  border-radius: 12px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-top: 10px;

  .item-interactive-disabled * {
    opacity: 1;
  }

  ion-list {
    background-color: transparent;
  }

  ion-item {
    --background: transparent;
  }
}

.sc-ion-popover-md-h {
  --box-shadow: none;
}

.popover-viewport.sc-ion-popover-md {
  border: 2px solid #ececec;
  margin-top: 11px;
}

/**mobile**/
@media (max-width: 992px) {
  #beeex-main-container {
    margin-top: 53px;
  }

  #beeex-profile-edit-stepper {
    .mat-step {
      border: none !important;
    }

    .mat-step {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px;
    }
  }

  .mat-step-header {
    margin-bottom: 17px !important;
  }

  .beeex-testimonials {
    margin-bottom: 30px;
  }

  .beeex-sub-main-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .sc-ion-popover-ios-h {
    max-height: 78px !important;
  }

  .sc-ion-popover-md-h {
    max-height: 78px !important;
  }

  .beeex-header-ausgleich {
    margin-bottom: -87px;
  }

  .beeex-supportedfields {
    margin-top: 20px;
  }

  .beeex-inputform {
    .swiper-pagination {
      bottom: 170px !important;
    }
    .swiper-pagination-bullet {
      width: 17px;
    }
  }

  .beeex-hideonmobile {
    display: none;
  }

  .beeex-form-container {
    background-size: 85%;
    background-position: 325% -3%;

    h1 {
      font-size: 40px;
    }
  }

  .beeex-inputform {
    margin-top: 28px;
  }

  .beeex-content-container {
    margin-bottom: 50px;
    margin-top: 25px;
  }
}

ion-content > .beeex-content-thumbnail {
  min-width: 100%;
  min-height: 200px;
  object-fit: cover;
  margin-top: 79px;
  @media (max-width: 992px) {
  }

  @media (min-width: 992px) {
    margin-top: 98px;
  }
}

.beeex-content-title {
  color: white;

  @media (max-width: 992px) {
    font-size: 37px;
    line-height: 37px;
    margin-top: -260px;
    margin-bottom: 50px;

    h2 {
      margin-top: 7 px;
      font-size: 19px;
    }
  }

  @media (min-width: 992px) {
    font-size: 54px;
    margin-top: -280px;
    margin-bottom: 80px;

    h2 {
      margin-top: 0px;
    }
  }
}

.beeex-contentblock-text {
  margin-top: auto;
  margin-bottom: auto;
}

.beeex-profile-checklist-container {
  text-align: center;
  background-color: #f7f8f9;
  border-radius: 12px;
  padding: 10px;

  ion-icon {
    font-size: 40px;
  }
}

.beeex-check {
  ion-icon {
    color: #2a435f !important;
  }
  h3 {
    color: #2a435f !important;
  }
  ion-button {
    color: white;
    border-color: #2a435f;
    background-color: #2a435f;
  }
}

.beeex-uncheck {
  ion-icon {
    color: #df2826 !important;
  }
  h3 {
    color: #df2826 !important;
  }
  ion-button {
    color: white;
    border-color: #df2826;
    background-color: #df2826;
  }
}

.beeex-contentblock {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .beeex-contentblock-left {
    padding-left: 24px;
  }

  .beeex-margin-10 {
    margin: 10px;
  }

  .beeex-align-desktop-right {
    text-align: right;
  }

  .beeex-contentblock-right {
    padding-right: 24px;
  }
}

.beeex-center {
  text-align: center;
}

.beeex-ctabtn {
  color: white;
  background-color: var(--ion-color-primary);
  border-radius: 7px;
  font-size: 17px;
  height: 53px;
  margin-top: 20px;
}

.beeex-ctabtn:hover {
  color: var(--ion-color-primary);
  background-color: #ececec;
  border: 1px solid var(--ion-color-primary);
}

.beeex-side-distance {
  margin-left: 10%;
  margin-right: 10%;

  figcaption h1 {
    font-size: 24px !important;
    color: #2a435f !important;
  }
  figcaption p {
    font-size: 19px !important;
  }

  figure.image figcaption {
    background: white !important;
    border: 1px solid #2a435f;
  }
}

.ion-page {
  background-color: white;
}

.label-stacked.sc-ion-label-md-h,
.label-floating.sc-ion-label-md-h {
  color: #2a435f !important;
  font-weight: 500;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .beeex-form-container {
    width: 992px;
  }
}

.beeex-form-checkbox {
  --border-color: #2a435f;
}

.beeex-form-list {
  width: 100%;
  padding: 0;
}

.beeex-social-media-links {
  font-size: 30px;

  text-align: center;

  a:not(:first-child) {
    margin-left: 20px;
  }
}

@media screen and (max-width: 576px) {
  .beeex-hide-sm {
    display: none;
  }

  .beeex-margin-10 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .beeex-hide-md {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .beeex-hide-lg {
    display: none;
  }
}

.beeex-search-popover .popover-content {
  position: absolute;
  top: 50px !important;
  width: 95%;
}

/****************************************************************
 *
 * CSS Percentage Circle
 * Author: Andre Firchow
 *
*****************************************************************/
.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill {
  border: 0.08em solid #204e76;
}
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill {
  border: 0.08em solid #31486e;
}
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill {
  border: 0.08em solid #434267;
}
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill {
  border: 0.08em solid #543c5f;
}
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill {
  border: 0.08em solid #663657;
}
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill {
  border: 0.08em solid #77304f;
}
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill {
  border: 0.08em solid #892b48;
}
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill {
  border: 0.08em solid #9a2540;
}
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill {
  border: 0.08em solid #cf1329;
}
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  border: 0.08em solid #e00d21;
}

.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #df2826;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  float: left;
  margin: 0 0em 0em 0;
  background-color: #cccccc;
}
.c100 *,
.c100 *:before,
.c100 *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.c100.center {
  float: none;
  margin: 0 auto;
}
.c100.big {
  font-size: 209px;
}
.c100.small {
  font-size: 80px;
}
.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #2a435f;
  display: block;
  text-align: center;
  white-space: break-spaces;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: white;
  width: 0.84em;
  height: 0.84em;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -o-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -o-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -o-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -o-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -o-transform: rotate(18deg);
  transform: rotate(18deg);
}
.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -o-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -o-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -o-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -o-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -o-transform: rotate(36deg);
  transform: rotate(36deg);
}
.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -o-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -o-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.c100.p13 .bar {
  -webkit-transform: rotate(46.8deg);
  -moz-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  -o-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -o-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  transform: rotate(54deg);
}
.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -o-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -o-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -o-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -o-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -o-transform: rotate(72deg);
  transform: rotate(72deg);
}
.c100.p21 .bar {
  -webkit-transform: rotate(75.6deg);
  -moz-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  -o-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -o-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -o-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -o-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.c100.p26 .bar {
  -webkit-transform: rotate(93.6deg);
  -moz-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  -o-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -o-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -o-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -o-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -o-transform: rotate(108deg);
  transform: rotate(108deg);
}
.c100.p31 .bar {
  -webkit-transform: rotate(111.6deg);
  -moz-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  -o-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -o-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -o-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -o-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -o-transform: rotate(126deg);
  transform: rotate(126deg);
}
.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -o-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.c100.p37 .bar {
  -webkit-transform: rotate(133.2deg);
  -moz-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  -o-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -o-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -o-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -o-transform: rotate(144deg);
  transform: rotate(144deg);
}
.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -o-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.c100.p42 .bar {
  -webkit-transform: rotate(151.2deg);
  -moz-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  -o-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -o-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -o-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -o-transform: rotate(162deg);
  transform: rotate(162deg);
}
.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -o-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.c100.p47 .bar {
  -webkit-transform: rotate(169.2deg);
  -moz-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  -o-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -o-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -o-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -o-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.c100.p52 .bar {
  -webkit-transform: rotate(187.2deg);
  -moz-transform: rotate(187.2deg);
  -ms-transform: rotate(187.2deg);
  -o-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -o-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -o-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -o-transform: rotate(198deg);
  transform: rotate(198deg);
}
.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -o-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.c100.p57 .bar {
  -webkit-transform: rotate(205.2deg);
  -moz-transform: rotate(205.2deg);
  -ms-transform: rotate(205.2deg);
  -o-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -o-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -o-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -o-transform: rotate(216deg);
  transform: rotate(216deg);
}
.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -o-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.c100.p62 .bar {
  -webkit-transform: rotate(223.2deg);
  -moz-transform: rotate(223.2deg);
  -ms-transform: rotate(223.2deg);
  -o-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -o-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -o-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -o-transform: rotate(234deg);
  transform: rotate(234deg);
}
.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -o-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.c100.p67 .bar {
  -webkit-transform: rotate(241.2deg);
  -moz-transform: rotate(241.2deg);
  -ms-transform: rotate(241.2deg);
  -o-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -o-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -o-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -o-transform: rotate(252deg);
  transform: rotate(252deg);
}
.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -o-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -o-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -o-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.c100.p74 .bar {
  -webkit-transform: rotate(266.4deg);
  -moz-transform: rotate(266.4deg);
  -ms-transform: rotate(266.4deg);
  -o-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -o-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -o-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -o-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.c100.p79 .bar {
  -webkit-transform: rotate(284.4deg);
  -moz-transform: rotate(284.4deg);
  -ms-transform: rotate(284.4deg);
  -o-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -o-transform: rotate(288deg);
  transform: rotate(288deg);
}
.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -o-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -o-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -o-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.c100.p84 .bar {
  -webkit-transform: rotate(302.4deg);
  -moz-transform: rotate(302.4deg);
  -ms-transform: rotate(302.4deg);
  -o-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -o-transform: rotate(306deg);
  transform: rotate(306deg);
}
.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -o-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -o-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -o-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.c100.p89 .bar {
  -webkit-transform: rotate(320.4deg);
  -moz-transform: rotate(320.4deg);
  -ms-transform: rotate(320.4deg);
  -o-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -o-transform: rotate(324deg);
  transform: rotate(324deg);
}
.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -o-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -o-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -o-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.c100.p94 .bar {
  -webkit-transform: rotate(338.4deg);
  -moz-transform: rotate(338.4deg);
  -ms-transform: rotate(338.4deg);
  -o-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -o-transform: rotate(342deg);
  transform: rotate(342deg);
}
.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -o-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -o-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -o-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.c100.p99 .bar {
  -webkit-transform: rotate(356.4deg);
  -moz-transform: rotate(356.4deg);
  -ms-transform: rotate(356.4deg);
  -o-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.c100:hover {
  cursor: default;
}
.c100:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  color: #df2826;
}
.c100:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}
.c100.dark {
  background-color: #777777;
}
.c100.dark .bar,
.c100.dark .fill {
  border-color: #c6ff00 !important;
}
.c100.dark > span {
  color: #777777;
}
.c100.dark:after {
  background-color: #666666;
}
.c100.dark:hover > span {
  color: #c6ff00;
}
.c100.green .bar,
.c100.green .fill {
  border-color: #4db53c !important;
}
.c100.green:hover > span {
  color: #4db53c;
}
.c100.green.dark .bar,
.c100.green.dark .fill {
  border-color: #5fd400 !important;
}
.c100.green.dark:hover > span {
  color: #5fd400;
}
.c100.orange .bar,
.c100.orange .fill {
  border-color: #dd9d22 !important;
}
.c100.orange:hover > span {
  color: #dd9d22;
}
.c100.orange.dark .bar,
.c100.orange.dark .fill {
  border-color: #e08833 !important;
}
.c100.orange.dark:hover > span {
  color: #e08833;
}

#beeex-cookie-description {
  font-weight: 500;
  font-size: 13px;
}

#cookies-eu-accept {
  font-size: 17px;
  background-color: #d12228;
  border-radius: 30px;
  padding: 12px;
  color: white;
}

#cookies-eu-reject {
  background-color: transparent;
  font-weight: 500;
  text-decoration: underline;
}

#beeex-cookie-table {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

#cookies-eu-banner {
  padding-top: 10px;
  padding-bottom: 19px;
  border-bottom: 1px solid #2a435f;
}

@media (max-width: 992px) {
  .c100.big {
    margin-top: 28px;
  }

  #beeex-cookie-col1 {
    display: none;
  }
  #beeex-cookie-col2 {
    display: inline-block;
    width: 100%;
  }
  #beeex-cookie-col3 {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  #beeex-cookie-col1 {
    display: inline-block;
    width: 5%;
  }

  .beeex-category-icon {
    ion-icon {
      margin-left: -22px;
    }
  }

  .beeex-social-media-links {
    margin-top: 50px;
  }
  #beeex-cookie-col2 {
    display: inline-block;
    width: 74%;
  }
  #beeex-cookie-col3 {
    display: inline-block;
    width: 20%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.beeex-modal-setuserrole .modal-wrapper {
  height: 270px;
  width: 400px;
}

@media (min-width: 992px) {
  .beeex-modal-share-form {
    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 80vh;
        overflow: auto;
      }
    }
  }
}

.beeex-modal-spartax-order-success {
  --height: auto;
  --border-radius: 25px;
}

.alert-input-group {
  margin-top: -31px;
}

.beeex-context-menu-icon {
  cursor: pointer;
}

.picker-button.sc-ion-picker-md,
.picker-button.ion-activated.sc-ion-picker-md {
  background-color: #2a435f;
  color: white;
  border-radius: 100px;
  margin-right: 15px;
}

.picker-toolbar.sc-ion-picker-md {
  height: 64px;
  padding-top: 15px;
}

.beeex-hidden {
  visibility: hidden;
}

.beeex-spinner-container {
  width: 100%;
  text-align: center;
  ion-spinner {
    transform: scale(4);
    --color: #2a435f;
    margin-top: 50px;
    margin-bottom: 70px;
  }
}

#beeex-profile-edit-stepper {
  .mat-step-icon {
    color: white;
    padding: 20px;
  }

  .mat-step-icon-state-edit {
    background-color: #d8202a;
  }

  .mat-step-icon-state-done {
    background-color: #2a435f;
  }

  .mat-step-icon-state-number{
    background-color: #2a435f;
  }

  .mat-step-icon-selected{
    background-color: #d8202a !important;; 
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 17px;
  }
}

//Forms

.mat-vertical-stepper-header {
  height: 45px !important;

  .mat-step-icon {
    background-color: #e4e4e4;
    color: #fff;
    padding: 3px 18px;
    margin-right: 10px;
    font-size: 28px;
    border-radius: 0px;
    height: 57px;
  }

  .mat-step-icon-selected {
    background-color: #e00d21;
  }
}

.mat-step-text-label {
  font-size: 36px;
  font-weight: 500;
  color: #2a435f;
}

.mat-step {
  border: 2px solid #e4e4e4;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
}

app-forms-range {
  ion-label {
    font-size: 26px;
    color: #304964;
  }

  p {
    margin-top: 5px;
    font-size: 20px;
  }

  ion-range {
    margin-top: -35px;
    margin-bottom: -16px;
  }
}

@media (max-width: 992px) {
  .mat-vertical-content {
    padding: 0px 0px 0px 0px !important;
  }

  .mat-vertical-content-container {
    margin-left: 0px !important;
  }

  .beeex-category-icon {
    ion-icon {
      margin-left: -15px;
    }
  }
}

.beeex-category-icon {
  ion-icon {
    color: white;
    background-color: #2a435f;
    padding: 7px;
    border-radius: 100%;
    margin-right: 15px;
    font-size: 25px;
  }
}

.beeex-category-button-active {
  background-color: #e00d21;
  .beeex-catname {
    color: white !important;
  }
}

.beeex-toast-success {
  --background: #304964 !important;
  --border-radius: 100px !important;
  color: white !important;
}

.beeex-toast-failed {
  --background: #e00d21 !important;
  --border-radius: 100px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.item-background-color {
  border-bottom: none;
}

.beeex-inputform .item-interactive.ion-valid.item-has-focus {
  --inner-border-width: 0 0 2px 0 !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #f8f9fa;
  border: 2px solid #78899a;
  font-weight: 600;
  line-height: 11px;
  color: #2a435f;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  font-size: 12px !important;
  transition: opacity 0.3s;
}

.beeex-submitbtn-red {
  border: 1.8px solid #ffffff;
  border-radius: 100px;
  background-color: #df2826;
  color: #ffffff;
}

::-moz-selection {
  background: #df2826;
  color: #fff;
}
::selection {
  background: #df2826;
  color: #fff;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2a435f transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.alert-sub-title.sc-ion-alert-md {
  font-size: 21px !important;
  color: #5e5e5e !important;
}

ion-alert .alert-head.sc-ion-alert-md + .alert-message.sc-ion-alert-md {
  font-size: 16px !important;
  color: #5e5e5e !important;
}

.beeex-stepper-horizontal-box {
  width: 48px;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  max-height: 48px;
  min-height: 48px;
  border-radius: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-top: 2px;
  font-size: 35px;
}

.beeex-stepper-horizontal-box-active {
  background-color: #df1221;
}

.beeex-stepper-horizontal-title-active {
  color: black;
}

.beeex-stepper-horizontal-box-inactive {
  background-color: #efb8b7;
}

.beeex-stepper-horizontal-title-inactive {
  color: #646464;
}

.toolbar-background {
  background-color: white !important;
}

.beeex-stepper-horizontal-container {
  display: flex;
}

.beeex-stepper-horizontal-title {
  font-size: 24px;
  font-weight: 500;
  padding-left: 10px;
}

.beeex-stepper-grid {
  margin-bottom: 19px;
}

.beeex-stepper-horizontal-box {
  ion-icon {
    margin-top: 4px;
    margin-left: -1px;
  }
}

.beeex-stepper-hr {
  margin-bottom: 25px;
}

hr {
  height: 0px;
  border-width: 1px;
}

@media (min-width: 992px) {
  .beeex-stepper-horizontal-title {
    padding-top: 11px;
  }
}

@media (max-width: 992px) {
  .beeex-stepper-horizontal-box {
    width: 30px;
    max-width: 30px;
    min-width: 30px;
    height: 30px;
    max-height: 30px;
    min-height: 30px;
    text-align: center;
    padding-top: 0px;
    font-size: 23px;
  }

  .beeex-stepper-horizontal-title {
    font-size: 18px;
  }

  .beeex-stepper-horizontal-container {
    display: flex;
  }
}

p {
  color: #616262 !important;
  font-weight: 400;
}

.beeex-thumbnail {
  position: relative;
}
.beeex-verifyed-badge {
  float: left;
  position: absolute;
  left: 20px;
  top: 7px;
  z-index: 1000;
  background-color: #df1521;
  padding: 5px;
  color: #ffffff;
  font-weight: normal;
  border-radius: 100px;
  text-align: center;
  font-size: 13px;
}

.tooltiptext {
  font-weight: normal;
}

.alert-checkbox-label.sc-ion-alert-md {
  white-space: break-spaces !important;
}
/*.alert-tappable.sc-ion-alert-md {
  height: 76px !important;
}
*/
.beeex-testimonials {
  ion-image {
    height: 100px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    border-radius: 100px;
  }
}

.beeex-testimonials {
  position: relative;
  background: #ffffff;
  border: 2px solid #2a435f;
}
.beeex-testimonials:after,
.beeex-testimonials:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.beeex-testimonials:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 25px;
  margin-left: -25px;
}
.beeex-testimonials:before {
  border-color: rgba(42, 67, 95, 0);
  border-top-color: #2a435f;
  border-width: 28px;
  margin-left: -28px;
}

.swiper-wrapper {
  margin-bottom: 65px;
}

swiper-container {
  margin-bottom: -17px;
}

.beeex-testimonials {
  img {
    max-width: 100px;
  }
  h5 {
    margin-top: -6px;
    font-size: 14px;
  }
}

/**desktop**/
@media (min-width: 992px) {
  #beeex-filter-header {
    margin-top: 110px;
    padding: 25px;
  }

  #tab-menu {
    .beeex-tab-button {
      border: 2px solid #acacac;
      border-radius: 14px;
      padding: 10px;
      font-size: 18px;
      height: 53px;
      color: black !important;
      cursor: pointer;
  
      ion-icon{
        margin-right: 10px;
      }
    }
  }

  #beeex-profile-edit-stepper {
    .mat-step-icon {
      font-size: 25px;
    }
  }

  .beeex-field-container {
    padding-top: 62px;
    padding-bottom: 62px;
  }

  .beeex-field {
    h3 {
      font-size: 18px;
    }
  }
}

/**mobile**/
@media (max-width: 992px) {
  #beeex-filter-header {
    margin-top: 85px;
  }

  .beeex-back-box {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .beeex-category-box {
    margin-bottom: 20px !important;
  }

  .beeex-field-container {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .beeex-ausgleich {
    margin-top: 66px !important;
  }

  .beeex-field {
    h3 {
      font-size: 17px;
    }
  }
}

.beeex-back-button {
  .content {
    padding-left: 7px;
  }

  font-weight: 500;
  cursor: pointer;
}

.beeex-border-button {
  background-color: #80808024;
  border-radius: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
}

#beeex-category-headline {
  font-size: 23px;
  margin-top: -28px;
  color: #2a435f;
}

#beeex-category-headline-label {
  font-size: 13px;
}

#toTopBtn {
  display: none; /*Hidden by default */
  position: fixed;
  bottom: 45px;
  left: 14px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #2a435f54;
  color: white;
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 100px;
  font-size: 18px;
  ion-icon {
    font-size: 31px;
  }
}

#toTopBtn:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}

.beeex-forms-stepper-button {
  border-radius: 360px;
  height: 36px;
  background-color: var(--ion-color-primary);
  color: white;

  margin-right: 10px;
  padding-left: 20px;
  padding-right: 20px;

  &:disabled {
    background-color: gainsboro;
  }
}

@media (max-width: 992px) {
  .beeex-forms-stepper-button {
    margin-top: 7px;
  }
}

@media (min-width: 992px) {
  .beeex-forms-stepper-button {
    margin-top: 18px;
  }
}

.beeex-forms-stepper-step-position-mark {
  height: 0px;
}

.beeex-form-readmode {
  ::ng-deep .mat-vertical-stepper-content {
    visibility: visible !important;
    height: 100% !important;
  }
}

.beeex-button-previous {
  background-color: transparent;
  color: #2a435f;
  padding: 0px;
  margin-right: 8px;
}

ion-checkbox {
  --border-radius: 100px;
}

.alert-wrapper.sc-ion-alert-md {
  border-radius: 19px !important;
  padding: 9px !important;
}

.alert-button.beeex-cancel-alert {
  font-size: 16px !important;
  color: #4a4a4a !important;
}

.alert-button.beeex-ok-button {
  border: 2px solid #2b4460;
  border-radius: 87px !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}

ion-radio-group[disabled]:not([disabled="false"]) {
  opacity: 0.3;
  pointer-events: none;
}

ion-label[disabled]:not([disabled="false"]) {
  opacity: 0.3;
}

.fa-user-tie {
  color: white;
  background-color: #313e63;
  border-radius: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
}

.fa-user {
  color: white;
  background-color: #d91b2b;
  border-radius: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
}

.fa-child-reaching {
  color: white;
  background-color: #bababa;
  border-radius: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 9px;
  padding-right: 8px;
}

.ion-focused {
  --background-focused: transparent !important;
}
//--------------------------------------------------------------------------
//beeexpert 4.0

//Tablet
@media (max-width: 958px) and (min-width: 770px) {
  .beeex4-hide-on-tablet {
    display: none !important;
  }

  #tab-menu {
    .beeex-tab-button {
      border: 2px solid #acacac;
      border-radius: 14px;
      padding: 10px;
      font-size: 18px;
      height: 53px;
      color: black !important;
      cursor: pointer;
  
      ion-icon{
        margin-right: 10px;
      }
    }
  }

  ion-modal {
    --height: 600px;
  }

  .beeex-4-smaller-text {
    font-size: 25px;
  }

  .beeex4-left-side-image {
    max-width: 490px;
    margin-left: auto;
    margin-right: auto;
  }

  ::ng-deep .swiper-button-prev {
    color: white !important;
  }



  .beeex4-content-container {
    padding-left: 1%;
    padding-right: 1%;
    text-align: center;

    ion-row {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h1 {
    font-size: 56px;
    color: #2a435f;
    font-weight: 600;
  }

  h2 {
    font-size: 36px !important;
    color: #2a435f;
    font-weight: 600;
  }

  h3 {
    font-size: 31px;
    font-weight: 600;
    text-align: center;
  }

  p {
    font-size: 24px;
    color: #5e5e5e;
    font-weight: 400;
  }

  .beeex4-small-text {
    font-size: 20px;
    color: #5e5e5e;
    font-weight: 400;
    text-align: center;
  }

  .beeex4-small-link {
    font-size: 20px;
    color: #df1521;
    font-weight: 400;
    text-decoration: underline;
    text-align: center;
  }
}




//Desktop
@media (min-width: 958px) {
  .beeex4-hide-on-desktop {
    display: none !important;
  }

  ion-modal {
    --height: 600px;
  }

  .beeex-4-loading-container {
    #loading-spinner {
      max-width: 38%;
    }
  }

  .beeex4-changeMatchignO-alert .alert-wrapper {
    --max-width: 528px !important;
    --min-width: 528px !important;
    max-width: 528px !important;
    min-width: 528px !important;
  }

  .beeex-4-smaller-text {
    font-size: 25px;
  }

  .beeex4-left-side-image {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 4%;
  }

  .beeex4-content-container {
    padding-left: 10.3%;
    padding-right: 10.3%;

    ion-row {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h1 {
    font-size: 81px;
    color: #2a435f;
    font-weight: 600;
  }

  h2:not(ion-alert h2) {
    font-size: 54px !important;
    color: #2a435f;
    font-weight: 600;
}

  h3 {
    font-size: 31px;
    font-weight: 600;
    text-align: center;
  }

  p {
    font-size: 33px;
    color: #5e5e5e;
    font-weight: 400;
  }

  .beeex4-small-text {
    font-size: 20px;
    color: #5e5e5e;
    font-weight: 400;
    text-align: center;
  }

  .beeex4-small-link {
    font-size: 20px;
    color: #df1521;
    font-weight: 400;
    text-decoration: underline;
    text-align: center;
  }
}

//Mobile
@media (max-width: 770px) {
  .beeex4-hide-on-mobile {
    display: none !important;
  }

  #tab-menu {
    .beeex-tab-button {
      border: 2px solid #acacac;
      border-radius: 14px;
      padding: 10px;
      font-size: 16px;
      height: 45px;
      width: 100%;
      color: black !important;
      cursor: pointer;
  
      ion-icon{
        margin-right: 10px;
      }
    }
  }

  .beeex-4-smaller-text {
    font-size: 18px !important;
  }

  .beeex4-left-side-image {
    max-width: 310px;
    margin-left: auto;
    margin-right: auto;
  }

  .beeex4-content-container {
    padding-left: 4.7%;
    padding-right: 7.7%;

    h1 {
      font-size: 23px;
      color: black;
      font-weight: normal;
      text-align: left;
    }

    h2 {
      font-size: 24px !important;
      color: #2a435f;
      font-weight: 600;
      text-align: center;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    p {
      font-size: 21px;
      color: #5e5e5e;
      font-weight: 400;
    }

    .beeex4-small-text {
      font-size: 15px;
      color: #5e5e5e;
      font-weight: 400;
      text-align: center;
    }

    .beeex4-small-link {
      font-size: 15px;
      color: #df1521;
      font-weight: 400;
      text-decoration: underline;
      text-align: center;
    }
  }

  .beeex4-center-mobile-container {
    text-align: center;
  }
}

ion-img {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.beeex4-shine-button {
  ion-button {
    border: none;
    background: #df2826;
    position: relative;
    overflow: hidden;
    border-radius: 200px;
  }

  .beeex4-shine-button-element:before {
    content: "";
    position: absolute;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
  }

  @keyframes shine {
    0% {
      left: -100px;
    }
    20% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }

  .beeex4-shine-button-element:before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    animation: shine 3s infinite linear; /* Animation */
  }

  swiper-slide:before {
    width: 400px !important;
    animation: shine 9s infinite linear !important; /* Animation */
    transition: all 1s ease-in-out;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0) 70%
    ) !important;
  }

  .ion-color-secondary {
    --ion-color-base: transparent !important;
  }
}

.beeex4-animate-on-visit.beeex4-animate-on-visit-glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

.beeex4-animate-on-visit {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.beeex-4-loading-container {
  text-align: center;

  .beeex-loading-text {
    font-size: 18px;
  }
}

.alert-button-role-cancel {
  font-size: 16px !important;
}

.alert-button-role-confirm {
  font-weight: 600 !important;
}

.item-native{
  background: transparent !important;
}

.companyprofile-edit-popup{
  h2{
    font-size: 12px !important;
  }
}

.single-select-alert{
    --max-width: unset !important;
  }